import React, { useContext } from "react";
import { Link } from "gatsby";
import { ThemeContext } from "context/theme-context";
import { LocaleContext, LocationContext } from "context/locale-context";
import useTranslations from "hooks/useTranslations";
import MdxLink from "components/mdxLink";
import { mq } from "utils/helper";
import { split, slice } from "ramda";

export const menuItems = (locale = "en", path) => [
  {
    id: 1,
    title: "findings",
    goto: `${path}/findings`,
  },
  {
    id: 2,
    title: "data",
    goto: `https://syrianarchive.org/${locale}/data-archive`,
  },
  {
    id: 3,
    title: "methodology",
    goto: `${path}/methodology`,
  },
];

export default function Navigation({ place }) {
  const { colors } = useContext(ThemeContext);
  const location = useContext(LocationContext);
  const pathname = location?.pathname;
  const path = pathname?.replace("/ar/", "")?.replace("/en/", "");
  const realPath =
    path?.charAt(path.length - 1) === "/"
      ? path.substring(0, path.length - 1)
      : path;

  const style = {
    ul: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      listStyle: "none",
      alignItems: "center",
      margin: 0,
      [mq[0]]: {
        display: "block",
      },
    },
    li: {
      padding: "0.5rem",
      color: colors.light,
      margin: 0,
      textTransform: "uppercase",
      fontWeight: "500",
    },
  };

  const layoutStyle = style;

  const homeStyle = {
    ul: {
      ...style.ul,
      width: "640px",
      [mq[0]]: {
        width: "auto",
        display: "block",
        textAlign: "center",
      },
    },
    li: {
      ...style.li,
      marginTop: "1rem",
      opacity: "0.8",
      fontSize: "20px",
      fontWeight: "900",
      letterSpacing: "0.59px",
      lineHeight: "23px",
    },
  };
  const tr = useTranslations();
  const locale = useContext(LocaleContext);
  const isAra = locale === "ar";
  const design = place === "layout" ? layoutStyle : homeStyle;
  return (
    <ul css={[design.ul, { direction: isAra ? "rtl" : "ltr" }]}>
      {menuItems(locale, realPath).map((item) => (
        <MdxLink key={item.id} href={item.goto}>
          <li
            css={{
              ...design.li,
              backgroundColor: place === colors.primary,
            }}
          >
            {tr(item.title)}
          </li>
        </MdxLink>
      ))}

      <Link
        css={{
          paddingTop: "0.3rem",
          ...design.li,
          ":hover": {
            color: colors.light,
          },
        }}
        to={isAra ? `/en/${realPath}/` : `/ar/${realPath}/`}
      >
        <li css={{ marginBottom: isAra ? 0 : "0.3rem" }}>
          {isAra ? "English" : "عربي"}
        </li>
      </Link>
    </ul>
  );
}
